<template>
  <div
    id="search-bar"
    data-cy-region="search-bar"
    class="SearchBar relative flex items-center"
    :class="{
      'w-75': !show,
      'SearchBar--open': show,
    }"
  >
    <form method="get" action="/search" class="w-full">
      <TextInput
        id="search-quick-search"
        class="outline-none text-base text-primary-1-100"
        type="text"
        name="q"
        autocomplete="off"
        role="combobox"
        :placeholder="t('searchProducts')"
        :aria-label="t('searchProducts')"
        icon="search"
        :dark="true"
        full-border
        light
        :is-optional="false"
        @focus="() => (isFocusing = true)"
        @input="
          (e: string) => {
            search = e;
            touched = true;
          }
        "
      >
        <template #after>
          <button
            v-if="show"
            type="button"
            aria-label="clear search words "
            class="[ absolute z-10 ] [ flex items-center justify-center ]"
            :class="{
              'h-6 w-6 right-3 ': show,
              'underline p-1 rounded-full right-5': !show,
            }"
            @click="
              () => {
                search = '';
                forceTriggerInputBlur();
              }
            "
          >
            <svg-icon-close
              class="text-white"
              :class="{
                'text-primary-1-100': show,
                'text-white': !show,
              }"
              width="22"
              height="22"
            />
          </button>
        </template>
        <template #hint>
          <div />
        </template>

        <template #icon>
          <svg-icon-search class="absolute left-3 z-10" />
        </template>
      </TextInput>
    </form>

    <transition name="fade">
      <div
        v-if="show"
        data-attr="searchResult__container"
        class="searchResult__container absolute bg-white w-full left-0 z-10 pt-4 overflow-y-auto"
      >
        <Spinner v-if="isFetching" class="text-primary-1-100 w-6 h-6 mx-auto mb-5" />
        <p v-else-if="noResults" class="py-2 text-center text-primary-1-100 mb-5">
          {{ t('noResults') }}
        </p>
        <div v-else>
          <div v-if="categoriesResult && categoriesResult.length" class="border-b-4 border-primary-1-05 pb-5 mb-5 px-5">
            <h2 class="text-primary-1-100 text-md font-bold">
              {{ t('suggestedCategories') }}
            </h2>

            <ul
              v-if="categoriesResult && categoriesResult.length"
              data-attr="searchResult__list"
              class="mt-4 flex flex-wrap gap-4 text-primary-1-100 text-sm"
            >
              <li v-for="(category, index) of categoriesResult" :key="index" class="flex flex-col text-center">
                <AppLink :to="`/${category.url_path}`" class="border border-primary-1-15 px-4 py-2">
                  {{ category.name }}
                </AppLink>
              </li>
            </ul>
          </div>

          <div v-if="productsResult && productsResult.length" class="px-5">
            <h2 class="text-primary-1-100 text-md font-bold">
              {{ t('suggestedProducts', { count: productsResult.length }) }}
            </h2>

            <ul
              v-if="productsResult && productsResult.length"
              data-attr="searchResult__list"
              class="search-result text-primary-1-100"
            >
              <li v-for="(item, index) of productsResult" :key="index">
                <AppLink :to="`/${item.slug}`" class="flex gap-x-5 w-full">
                  <img
                    v-if="item.thumb"
                    data-attr="searchResult__item__thumb"
                    class="object-contain"
                    :src="item.thumb.src"
                    :alt="item.thumb.alt"
                  />
                  <span>
                    <p data-attr="searchResult__item__name text-sm" class="text-black">{{ item.name }}</p>
                    <ProductPriceSection v-if="item" :product="item" class="mt-2 flex items-center gap-x-2" />
                  </span>
                </AppLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div
        v-if="show"
        class="Overlay"
        @click="
          () => {
            search = '';
            forceTriggerInputBlur();
          }
        "
      ></div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { object, string } from 'yup';

const { t, locale } = useI18n({
  useScope: 'local',
});

/**
 * A Feature Flag to determine to trigger the search upon hovering or not
 */
const IS_PASSIVE = false;

const route = useRoute();
const touched = ref(false);
const isFocusing = ref(false);

useForm({
  validationSchema: toTypedSchema(
    object({
      q: string(),
    }),
  ),
});

const { value, setValue } = useField<string>('q');

const {
  searchBy: search,
  result: productsResult,
  categoriesResult,
  execute,
  isFetching,
  // fetchBrands,
  // brands,
  // isFetchingBrands,
} = useSearchProduct(route?.query?.q?.toString() || '', {
  passive: true,
});

watch(value, newValue => {
  if (newValue) {
    search.value = newValue;
  }
});
const show = computed<boolean>(() => {
  if (IS_PASSIVE && isFocusing.value) {
    execute();
    fetchBrands();
    return true;
  }

  if (search.value && touched.value) {
    return true;
  }

  return !!(isFocusing.value && (productsResult.value?.length || categoriesResult.value?.length || isFetching.value));
});

onMounted(() => {
  document.addEventListener('click', e => {
    if (e.target instanceof Element && document.getElementById('search-bar')?.contains(e.target)) return;
    isFocusing.value = false;
  });

  setValue(route?.query?.q?.toString() || '');
});

// clear search to force close menu when navigating
watch(route, () => {
  search.value = '';
});

const noResults = computed(() => !productsResult.value?.length && !categoriesResult.value?.length && !isFetching.value);

function forceTriggerInputBlur() {
  isFocusing.value = false;
}
</script>

<style lang="postcss" scoped>
.Overlay {
  @apply fixed left-0 w-full bg-primary-1-100 bg-opacity-50;
  top: 120px;
  height: calc(100vh + 120px);
}

.SearchBar {
  width: 200px;
  transition: 0.8s width;

  &--open {
    width: 400px;
  }
}

:deep(.TextInput input) {
  border: 1px solid rgba(0, 0, 0, 0.5);
}

:deep(.TextInput input::placeholder) {
  @apply text-primary-1-100 font-normal;
}

.search-result {
  & li {
    @apply py-5 flex items-center;

    img {
      width: 60px;
      height: 87px;
    }

    span {
      @apply flex-1 flex flex-col justify-center;
    }
  }

  & li:not(:last-child) {
    @apply pb-4 border-b border-primary-1-15 border-opacity-50;
  }
}

.searchResult__container {
  max-height: calc(100vh - 120px);
  top: 100%;
}
</style>

<i18n>
  {
    "en": {
      "searchProducts": "Search Products",
      "suggestedProducts": "Product Suggestions ({count})",
      "suggestedCategories": "Categories",
      "noResults": "No results found"
    },
    "ar": {
      "searchProducts": "البحث عن المنتجات",
      "suggestedProducts": "منتجات مقترحة ({count})",
      "suggestedCategories": "الاقسام",
      "noResults": "لا توجد نتائج"
    }
  }
  
</i18n>
